import React from 'react';
import { useNavigate } from 'react-router-dom';
import getData from './service/dataService';

const TrainingOverview = ({ username, name }) => {
  const data = getData(username);
  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate(`/training/${id}/detail`);
  };

  return (
    <div className="subpage-container">
      <h2>Training Overview - {name}</h2>
      <table className="data-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Points</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id} onClick={() => handleRowClick(item.id)}>
              <td>{item.date}</td>
              <td>{item.points}</td>
              <td>{item.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TrainingOverview;
