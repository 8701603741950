const getTrainingDetail = (id) => {
  function randomCoordinate() {
    return Math.floor(Math.random() * 199) - 99;
  }

  function getRndHit(index) {
    let x = randomCoordinate();
    let y = randomCoordinate();
    
    const distance = Math.sqrt(x * x + y * y);
    const score = Math.max(0, 10.9 - 9.7*(distance / 100)); // Example scoring formula
    return {index: index, x: x, y: y, score: score.toFixed(1)};
  }

  const details = {
    1: [
      getRndHit(1),
      getRndHit(2),
      getRndHit(3),
      getRndHit(4),
      getRndHit(5),
      getRndHit(6),
      getRndHit(7),
      getRndHit(8),
      getRndHit(9),
      getRndHit(10),
    ],
    // Add more entries as needed
  };

  return details[1] //details[id] || [];
};

export default getTrainingDetail;
