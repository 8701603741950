import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import getTrainingDetail from './service/trainingDetailService';

const TrainingDetail = () => {
  const { id } = useParams();
  const [details, setDetails] = useState([]);

  useEffect(() => {
    const data = getTrainingDetail(id);
    setDetails(data);
  }, [id]);

  useEffect(() => {
    const canvas = document.getElementById('training-canvas');
    const ctx = canvas.getContext('2d');
    ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset transformation matrix to the identity matrix
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
    ctx.translate(canvas.width / 2, canvas.height / 2); // Translate the origin to the center of the canvas


    // Draw target background
    const drawTarget = () => {
      const colors = ['red', 'yellow', 'green', 'green', 'green', 'white', 'white', 'white', 'white', 'white'];
      const radiusStep = canvas.width / 20; // 10 circles, so each step is 1/20th of the canvas width
      const fontstep = radiusStep * 0.25;

      for (let i = 10; i > 0; i--) {
        ctx.beginPath();
        ctx.arc(0, 0, i * radiusStep, 0, 2 * Math.PI);
        ctx.fillStyle = colors[i - 1];
        ctx.fill();
        ctx.strokeStyle = 'black';
        ctx.stroke();
        // Draw numbers for rings 1 to 8
        if (i <= 9 && i > 1) {
          const positions = [
            { x: -fontstep, y: ((i+1) * radiusStep) - fontstep }, // Bottom
            { x: -fontstep, y: ((-i) * radiusStep) - fontstep }, // Top
            { x: ((i) * radiusStep) + fontstep, y: fontstep }, // Right
            { x: ((-i-1) * radiusStep) + fontstep, y: fontstep } // Left
          ];

          ctx.fillStyle = 'black';
          ctx.font = '16px Arial';
          positions.forEach(pos => {
            ctx.fillText(10 - i, pos.x, pos.y);
          });
        }
      }

      // Draw center point
      ctx.beginPath();
      ctx.arc(0, 0, 3, 0, 2 * Math.PI); // Small center point
      ctx.fillStyle = 'black';
      ctx.fill();


    };


    drawTarget(); // Draw target background

    // Draw red dots based on coordinates
    details.forEach((shot) => {
      // Adjust coordinates to match the new origin
      const adjustedX = shot.x * (2.5);
      const adjustedY = -shot.y * (2.5);
      console.log(canvas.width/100, adjustedX, adjustedY, shot)// Invert Y to match canvas coordinate system
      ctx.beginPath();
      ctx.arc(adjustedX, adjustedY, 6, 0, 2 * Math.PI);
      ctx.fillStyle = 'red';
      ctx.fill();
      ctx.strokeWidth = 1;
      ctx.strokeStyle = 'gray';
      ctx.stroke();
      // Draw index number
      ctx.fillStyle = 'white';
      ctx.font = '8px Arial';
      ctx.fillText(shot.index, adjustedX - 3, adjustedY + 3); // Offset the text slightly from the dot
    });
  }, [details]);

  return (
    <div className="training-detail-container">
      <h2>Training Detail</h2>
      <div className="training-detail-content">
        <canvas id="training-canvas" width="500" height="500" style={{ border: '1px solid black', marginRight: '20px' }}></canvas>
        <table className="data-table">
          <thead>
            <tr>
              <th>Index</th>
              <th>X Coordinate</th>
              <th>Y Coordinate</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {details.map((shot) => (
              <tr key={shot.index}>
                <td>{shot.index}</td>
                <td>{shot.x}</td>
                <td>{shot.y}</td>
                <td>{shot.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TrainingDetail;
