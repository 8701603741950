import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import TrainingDetail from './TrainingDetail';
import './App.css';
import TrainingOverview from './Subpage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <Login />
          } />
          <Route path="/training" element={<TrainingOverview username={localStorage.getItem('username')} name={localStorage.getItem('name')} />} />
          <Route path="/training-detail" element={<TrainingDetail />} />
          <Route path="/training/:id/detail" element={<TrainingDetail />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
