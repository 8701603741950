const getUsers = () => {
  return [
    { username: 'john.doe@example.com', password: 'password123', name: 'John Doe' },
    { username: 'jane.smith@example.com', password: 'password456', name: 'Jane Smith' },
    { username: 'alice.jones@example.com', password: 'password789', name: 'Alice Jones' },
    { username: 'test@test.com', password: 'test', name: 'Test User' },
  ];
};

export default getUsers;
