
const getData = (username) => {
  const data = {
    'john.doe@example.com': [
      { id: 1, date: '2024-09-25', points: 95, description: 'Excellent performance' },
      { id: 2, date: '2024-09-26', points: 88, description: 'Good performance' },
      { id: 3, date: '2024-09-27', points: 90, description: 'Consistent performance' },
      { id: 4, date: '2024-09-28', points: 87, description: 'Steady performance' },
      { id: 5, date: '2024-09-29', points: 93, description: 'Improved performance' },
      { id: 6, date: '2024-09-30', points: 89, description: 'Good effort' },
      { id: 7, date: '2024-09-28', points: 85, description: 'Satisfactory performance' },
      { id: 8, date: '2024-09-29', points: 88, description: 'Consistent performance' },
      { id: 9, date: '2024-09-30', points: 90, description: 'Good effort' },
      { id: 10, date: '2024-10-01', points: 91, description: 'Very good performance' },
      { id: 11, date: '2024-09-29', points: 87, description: 'Steady performance' },
      { id: 12, date: '2024-09-30', points: 89, description: 'Good effort' },
      { id: 13, date: '2024-10-01', points: 90, description: 'Consistent performance' },
      { id: 14, date: '2024-10-02', points: 92, description: 'Very good performance' },
      { id: 15, date: '2024-09-30', points: 88, description: 'Consistent performance' },
      { id: 16, date: '2024-10-01', points: 89, description: 'Good effort' },
      { id: 17, date: '2024-10-02', points: 90, description: 'Steady performance' },
      { id: 18, date: '2024-10-03', points: 91, description: 'Very good performance' },
      { id: 19, date: '2024-09-29', points: 90, description: 'Test performance' },
      { id: 20, date: '2024-09-30', points: 85, description: 'Improved test performance' },
      { id: 21, date: '2024-10-01', points: 88, description: 'Consistent test performance' },
      { id: 22, date: '2024-10-02', points: 87, description: 'Steady test performance' },
      { id: 23, date: '2024-10-03', points: 89, description: 'Good test effort' },
    ],
    'jane.smith@example.com': [
      { id: 1, date: '2024-09-27', points: 92, description: 'Very good performance' },
    ],
    'alice.jones@example.com': [
      { id: 1, date: '2024-09-28', points: 85, description: 'Satisfactory performance' },
    ],
    'test@test.com': [
      { id: 1, date: '2024-09-29', points: 90, description: 'Test performance' },
      { id: 2, date: '2024-09-30', points: 88, description: 'Consistent performance' },
      { id: 3, date: '2024-10-01', points: 89, description: 'Good effort' },
      { id: 4, date: '2024-10-02', points: 90, description: 'Steady performance' },
      { id: 5, date: '2024-10-03', points: 91, description: 'Very good performance' },
      { id: 6, date: '2024-09-29', points: 90, description: 'Test performance' },
      { id: 7, date: '2024-09-30', points: 85, description: 'Improved test performance' },
      { id: 8, date: '2024-10-01', points: 88, description: 'Consistent test performance' },
      { id: 9, date: '2024-10-02', points: 87, description: 'Steady test performance' },
      { id: 10, date: '2024-10-03', points: 89, description: 'Good test effort' },
    ],
  };

  return data[username] || [];
};

export default getData;
